<template>
    <state-machine
            v-if="rendered"
            :style="CSS + ';display:block;width:100%; height: 100%'"
            :states="states"
            :entities="entities"
            :edges="edges"
            :colors="colors"
            :mouseover="onMouseOver"
            :mouseout="onMouseOut"
            :click="onClick"
    ></state-machine>
</template>

<script>
import StateMachine from '@/core/infrastructure/components/StateMachine.vue'
import mixin from '../mixins'
export default {
  name: 'a-statemachine',
  components: {
    StateMachine
  },
  inject: {
    isEditor: {
      default: () => false
    },
    openRegistryCard: {
      default: () => false
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    pluginName: {
      type: String,
      description: 'Плагин',
      editor: 'Plugin'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    }
  },
  data () {
    return {
      states: [],
      entities: [],
      colors: ['#a0a9a7', '#de6232', '#168125', '#3a6ba0', '#1e4679'],
      edges: [],
      tooltipElem: null,
      rendered: false,
      onMouseOver: (stateId, entityId, event) => {
        let target = event.target

        let entity = this.entities[entityId]
        if (!entity.description) {
          return false
        }
        let tooltipHtml = entity.description
        // если у нас есть подсказка...
        /* let tooltipHtml = target.dataset.tooltip
        if (!tooltipHtml) return */

        // ...создадим элемент для подсказки

        this.tooltipElem = document.createElement('div')
        this.tooltipElem.className = 'tooltip'
        this.tooltipElem.innerHTML = tooltipHtml
        document.body.append(this.tooltipElem)

        // спозиционируем его сверху от аннотируемого элемента (top-center)
        let coords = target.getBoundingClientRect()

        let left = coords.left + 100

        if (left < 0) left = 0 // не заезжать за левый край окна

        let top = coords.top - this.tooltipElem.offsetHeight - 5
        if (top < 0) { // если подсказка не помещается сверху, то отображать её снизу
          top = coords.top + target.offsetHeight + 5
        }

        this.tooltipElem.style.position = 'fixed'
        this.tooltipElem.style.padding = '10px 20px'
        this.tooltipElem.style.border = '1px solid #b3c9ce'
        this.tooltipElem.style.borderRadius = '4px'
        this.tooltipElem.style.textAlign = 'center'
        this.tooltipElem.style.color = '#333'
        this.tooltipElem.style.background = '#fff'
        this.tooltipElem.style.boxShadow = '3px 3px 3px rgba(0, 0, 0, .3)'
        this.tooltipElem.style.width = '200px'

        this.tooltipElem.style.left = left + 'px'
        this.tooltipElem.style.top = top + 'px'
      },
      onMouseOut: (stateId, entityId, event) => {
        if (this.tooltipElem) {
          this.tooltipElem.remove()
          this.tooltipElem = null
        }
      },
      onClick: (stateId, entityId) => {
        let entity = this.entities[entityId]
        if (!entity.id || !entity.objectId || !entity.cardId) {
          return false
        }

        this.openRegistryCard({
          registryId: entity.objectId,
          cardId: entity.cardId,
          cardName: '',
          recordId: entity.id,
          initialData: {}
        })
      }
    }
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias}=${item.attribute}`)
          } else if (item.type === 'current_user') {
            filters.push(`${item.alias}=${this.$store.getters['Authorization/userId']}`)
          }
        })
      }
      return filters
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  async mounted () {
    if (this.isEditor()) {
      return false
    }
    await this.loadData()
  },
  methods: {
    async loadData () {
      if (!this.pluginName) {
        console.warn("plugin does't set")
        return false
      }
      let data = await this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.pluginName}?${this.dataFilters.join('&')}`)
      // let data = await this.$http.get(`http://localhost:8085/registryservice/plugins/execute/${this.pluginName}?${this.dataFilters.join('&')}`)
      this.states = data.data.states || []
      this.entities = data.data.entities || []
      this.edges = data.data.edges || []

      this.rendered = true
    }
  }
}
</script>

<style scoped>
</style>
